@import '@/styles/mixins.scss';

.menu-icon {
    cursor: pointer;
}

.icon-dark {
    stroke: var(--colors-primary-dark-70);
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background: rgba(0, 0, 0, 0.5);

    z-index: 999999;
}

.wrapper {
    @include flex-column;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;

    width: 320px;
    max-height: 100vh;

    padding: 20px;

    background: white;

    overflow: auto;

    // opacity: 0;
    // transform: translateX(100px);

    // &.open {
    //     opacity: 1;
    //     transform: translateX(0);
    // }

    /* width */
    &::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: var(--colors-gray-light-95);
        border-radius: 8px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: var(--colors-gray-light-50);
        border-radius: 5px;
    }
}

.top-section {
    @include flex-center;
    flex-direction: row;
    justify-content: space-between;

    padding-bottom: 20px;
    margin-bottom: 20px;

    border-bottom: 1px solid var(--colors-primary-light-90);
}

.logo {
    width: 84px;
    height: 28px;

    transition: 150ms ease-out;

    cursor: pointer;

    &:hover {
        transform: scale(1.2);
    }
}

.close-icon {
    width: 20px;
    height: 20px;

    stroke: var(--colors-gray-light-50);
}

.sidebar-stretch-wrapper {
    @include flex-column;
    justify-content: space-between;

    flex-grow: 1;
}

.content-wrapper {
    @include flex-column;

    gap: 12px;
}

.menu-item {
    transition: 0.2s ease-out;

    > a {
        @include caption-L;

        display: block;
        color: var(--colors-gray-dark-80);
        padding: 12px;
    }

    a {
        text-decoration: none;
    }

    .chevron-down {
        stroke: var(--colors-gray-light-30);
        width: 20px;
        height: 20px;
        margin-left: 8px;
        transition: 150ms ease-out;

        float: right;
    }
}

.child-open {
    background: var(--colors-primary-light-95);
    border-radius: 8px;

    .chevron-down {
        transform: rotate(180deg);
    }
}

.child-menu {
    @include flex-column;
    gap: 8px;
    margin-top: 8px;
}

.child-group {
    @include flex-column;

    gap: 12px;
    padding: 16px;
    background: var(--colors-gray-light-95);

    border-radius: 8px;
}

.group-title {
    @include caption-M-upper;
    color: var(--colors-gray);
    margin-bottom: 4px;
    white-space: nowrap;

    user-select: none;
}

.child-link {
    @include caption-M;
    color: var(--colors-gray-dark-50);
    white-space: nowrap;

    transition: 0.2 ease-out;

    &:hover {
        color: var(--colors-primary);
    }
}

.view-all {
    @include caption-M;
    color: var(--colors-gray);
}

.bottom-section {
    @include flex-column;
    gap: 24px;
    margin-top: 12px;

    hr {
        border: none;
        height: 1px;
        width: 100%;
        background: var(--colors-primary-light-90);
    }
}

.button {
    height: 43px !important;
    width: 100%;

    line-height: 41px !important;
}

.social-icons {
    @include flex-center;
    gap: 20px;

    svg {
        width: 20px;
        height: 20px;
    }
}

.tagline {
    @include caption-M;
    display: block;
    color: var(--colors-gray);
    text-align: center;
}

.mobile-menu-enter {
    opacity: 0;

    .wrapper {
        opacity: 0;
        transform: translateX(100%);
    }
}

.mobile-menu-enter-active {
    transition: 150ms ease-out;
    opacity: 1;

    .wrapper {
        transition: 150ms ease-out;
        transition-delay: 100ms;
        opacity: 1;
        transform: translateX(0);
    }
}

.mobile-menu-exit {
    opacity: 1;

    .wrapper {
        opacity: 1;
        transform: translateX(0);
    }
}

.mobile-menu-exit-active {
    transition: 150ms ease-out;
    opacity: 0;

    .wrapper {
        transition: 250ms ease-out;
        opacity: 0;
        transform: translateX(100%);
    }
}

@media screen and (max-width: 420px) {
    .wrapper {
        width: 100%;
    }
}
